.holdings {
  position: relative;
  max-width: 700px;
  min-height: 250px;
  padding: 0 0 1em;
  margin: 0 auto;
}

@media (min-width: 1000px) {
  .holdings {
    padding: 1em 0 2em 0;
  }
}

.holdingsInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.account {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 250px;
  padding: 3px 0;
}

.fiatvalue {
  position: relative;
  z-index: 2;
  padding: 1em 1em 0;
  margin: 0 0;
}

.fiatvalue > span:first-child {
  font-size: 1em;
  margin-bottom: .5em;
}

.holdingsOuter:not(.loading) .fiatvalue > div::before {
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  margin: 0 .7em 0 -.6em;
  vertical-align: 36%;
  padding: .25em;
  border-radius: 50%;
  animation: live 2s linear infinite;
  background: #fff;
  opacity: 0;
}

@keyframes live {
  0%, 30% {
    opacity: 0;
  }
  1% {
    opacity: .9;
  }
}

.percentdiff {
  background: rgba(0, 0, 0, .2);
  border-radius: .5em;
  padding: .3em .5em;
  display: inline-block;
  position: relative;
  vertical-align: 30%;
  font-weight: normal;
  margin: 0 0 .4em 0;
  border: none;
  box-shadow: none;
}

.percentdiff.up::before {
  content: "+";
}

.percentdiff:focus {
  box-shadow: 0 0 0 2px #aa8941;
}

.percentdiff:active {
  box-shadow: none;
}

.value {
  font-size: 2em;
  font-weight: 300;
}

.label {
  display: block;
  font-size: .875em;
  color: rgba(255, 255, 255, .55);
}

.products {
  position: relative;
  z-index: 3;
  flex: 0 1 260px;
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.products > div {
  background: #252627;
  display: flex;
  align-items: center;
  padding: .4em .5em;
}

.products > div > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.productamount > *:last-child,
.cashbalance > *:last-child {
  flex-basis: 1.8em;
  margin: 0 0 0 -.5em;
}

.infobutton {
  color: rgba(255, 255, 255, .4);
  padding: 0 0 0 .06em;
  border: 0;
  width: 1.8em;
  height: 1.8em;
  border-radius: 50%;
  box-shadow: none;
}

.productamount:first-child {
  border-radius: .5em .5em 0 0;
}

.productamount:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, .05);
}

.products .productinfo {
  box-sizing: border-box;
  font-size: 0.875em;
  color: rgba(255, 255, 255, .4);
  transition: all .2s ease-out;
  min-height: 0em;
  height: 0;
  padding: 0 .6em;
  opacity: 0;
  overflow: hidden;
}

.products .productinfo.visible {
  min-height: 7.3em;
  height: unset;
  padding: .4em .6em;
  opacity: 1;
  align-items: flex-start;
}

.productinfo table td {
  text-align: left;
  vertical-align: bottom;
  padding-bottom: .35em;
}

.productinfo table td:last-child {
  text-align: right;
  min-width: 90px;
  color: rgba(255, 255, 255, .55);
}

.cashbalance { 
  border-radius: 0 0 .5em .5em;
  border-top: 1px solid rgba(255, 255, 255, .05);
}

.negative {
  color: #ff333a;
}

.productamount .value,
.cashbalance .value {
  font-size: 1.1em;
  font-weight: 400;
}

.productamount .goldbar {
  margin: 0 .5em 0 0;
  height: 48px;
  filter: saturate(1.2) contrast(1.1);
  border-radius: .4em;
  box-shadow: 0 1px 2px rgba(0,0,0,.3);
}

.productamount .silverbar {
  margin: 0 .5em 0 0;
  height: 48px;
  filter: saturate(.1) brightness(.9) contrast(1.1);
  border-radius: .4em;
  box-shadow: 0 1px 2px rgba(0,0,0,.3);
}

.productamount .bar, .cashbalance .bar {
  margin: 0 .5em 0 0;
  height: 48px;
  border-radius: .4em;
  box-shadow: 0 1px 2px rgba(0,0,0,.3);
}

.cashbalance .dollar {
  cursor: pointer;
  margin: 0 .5em 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888888;
  font-size: 1.5em;
  font-family: Geometria, sans-serif;
  box-shadow: inset 1px 0 1px rgba(255,255,255,.12), 0 1px 2px rgba(0,0,0,.3);
  text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
  background: linear-gradient(to left bottom, #88888899 0%, #88888833 100%);
  border-radius: .25em;
  height: 48px;
  width: 27px;
}

.actions {
  padding: .5em 0 2em;
  display: none;
}

.actions.onlyone > * {
  display: none;
}

.actions.onlyone > *:first-child {
  display: inline-block;
  width: 220px !important;
}

.products .actions {
  background: none;
  margin-top: .5em;
  padding-bottom: 0;
  display: block;
}

.buy, .sell {
  width: 115px;
}

.pie {
  position: absolute;
  z-index: 1;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  background: #252627;
  box-shadow: -4px 4px 4px rgba(255,255,255,.12), 5px -5px 5px rgba(0,0,0,.12);
  transform: scale(1) rotate(-90deg);
  opacity: 1;
  animation: rotateIn .5s ease-out 1;
}

@keyframes rotateIn {
  0% {
    transform: scale(0.85) rotate(180deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(-90deg);
    opacity: 1;
  }
}

.pie circle {
  fill: transparent;
  transform-origin: 50% 50%;
  stroke-width: 1;
  stroke-dasharray: 100 100; /* for 75% */
  transition: all .5s ease-out;
}

.pie .gold {
  stroke: #f6c358;
}

.pie .silver {
  stroke: #bbbbbb;
}

.pie .platinum {
  stroke: #a5deff;
}

.pie .fiat {
  stroke: #52aa3d;
}

.soptions {
  display: flex;
  justify-content: space-between;
  max-width: 980px;
  margin: 0 auto;
  padding: 0 .7em .5em;
}

.soptions hr  {
  border: none;
  border-top: 1px solid rgba(255,255,255,.2);
  flex: 1;
  margin: .8em .5em;
}

.soptions button,
.soptions select {
  appearance: none;
  border: none;
  box-shadow: none;
  background: none;
  padding: 0 .4em;
  line-height: 1em;
  font-size: 16px;
  font-weight: bold;
  margin: 0 .05em;
  color: rgba(255,255,255,.5);
}

.soptions select {
  color: #fff;
  padding-right: 16px;
  background-position: calc(100% - 4px) center;
  background-repeat: no-repeat;
  background-size: 10px 6px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 7"><path d="M10 7L5.046 0 0 7z" fill="%23ffffff88" transform="rotate(180 5 3.6)" /></svg>');
}

.soptions option {
  color: #fff;
  background-color: #222;
}

.soptions button:hover,
.soptions select:hover {
  background-color: rgba(255,255,255,.15);
}

.soptions button:active,
.soptions select:active {
  box-shadow: none;
  background-color: rgba(255,255,255,.15);
}

.soptions button.active {
  color: #fff;
}

.soptions button:focus,
.soptions select:focus {
  box-shadow: 0 0 0 2px #aa8941;
}


.chart {
  color: white;
  height: 260px;
  max-width: 980px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 50px 100px rgba(0,0,0,.1);
}

.chart.loading {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/images/loader.svg');
}


@media (max-width: 600px) {
  .holdings {
    width: 100vw;
    max-width: unset;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(100vw);
    scroll-snap-type: x mandatory;
  }
  
  .holdingsInner {
    width: 200vw;
    min-height: 256px;
    flex-wrap: nowrap;
    scroll-snap-align: start;
  }

  .holdingsOuter {
    position: relative;
  }
  .holdingsOuter:before,
  .holdingsOuter:after {
    content: '';
    position: absolute;
    z-index: 10;
    bottom: 150px;
    left: calc(50% - 11px);
    padding: 4px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .6);
  }
  .holdingsOuter:after {
    left: calc(50% + 3px);
    background: rgba(255, 255, 255, .2);
  }
  .right.holdingsOuter:before {
    background: rgba(255, 255, 255, .2);
  }
  .right.holdingsOuter:after {
    background: rgba(255, 255, 255, .6);
  }
  .holdingsOuter.loading:before,
  .holdingsOuter.loading:after {
    display: none;
  }

  .holdingsInner > * {
    scroll-snap-align: center;
  }
  .actions {
    display: block;
  }
  .products {
    padding: 42px 1em;
  }
  .products .actions {
    display: none;
  }
}
